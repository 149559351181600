<template>
	<div>
		<div class="top-cover">
			<img src="../../assets/common/cover_4.png" alt>
		</div>

		<div class="nav-box">
			<div class="panel">
				<el-tabs v-model="activeName" tab-position="top" stretch>
					<el-tab-pane label="公司简介" name="first"></el-tab-pane>
					<el-tab-pane label="联系我们" name="second"></el-tab-pane>
					<el-tab-pane label="新闻动态" name="third"></el-tab-pane>
				</el-tabs>
			</div>
		</div>

		<div class="cont" v-show="activeName == 'first'">
			<div class="title-box cbc">
				<span id="title">公司简介</span>
				<span>ABOUT US</span>
			</div>
			<div v-html="info.content"></div>
		</div>

		<div class="cont" v-show="activeName == 'second'">
			<div class="title-box cbc">
				<span id="title">联系我们</span>
				<span>CONTACT US</span>
			</div>

			<div class="rbs">
				<img src="../../assets/common/cover_5.png" width="550" height="388">

				<div class="rss fww">
					<div class="item rsc">
						<img :src="require('@/assets/common/phone.png')" class="icon">
						<span>联系电话：{{info.about_us_phone}}</span>
					</div>
					<div class="item rsc">
						<img :src="require('@/assets/common/tel.png')" class="icon">
						<span>热线：{{info.about_us_tel}}</span>
					</div>
					<div class="item rsc">
						<img :src="require('@/assets/common/qq.png')" class="icon">
						<span>QQ：{{info.about_us_qq}}</span>
					</div>
					<div class="item rsc">
						<img :src="require('@/assets/common/email.png')" class="icon">
						<span>邮箱：{{info.about_us_mail}}</span>
					</div>
					<div class="item rsc">
						<img :src="require('@/assets/common/address.png')" class="icon">
						<span>地址:{{info.about_us_address}}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="cont" v-show="activeName == 'third'">
			<div class="title-box cbc">
				<span id="title">新闻动态</span>
				<span>NEWS</span>
			</div>
			<div class="card rsc" v-for="item in data" v-if="data.length">
				<el-image style="width: 350px;height: 350px;" :src="item.thumb"></el-image>
				<div class="item text">
					<div class="title">{{item.title}}</div>
					<div class="desc">{{item.intro}}</div>
				</div>
			</div>
			<el-empty v-else :image-size="200" description="暂无新闻"></el-empty>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeName: 'first',
				info: {},
				data: []
			}
		},
		mounted() {
			this.aboutUs()
		},
		watch: {
			async activeName(val) {
				if (val == 'third') this.data = await this.$api.newsList()
			}
		},
		methods: {
			async aboutUs() {
				let {
					info
				} = await this.$api.aboutUs()
				this.info = info
			},
		}
	}
</script>

<style lang="scss" scoped>
	.top-cover {
		width: 100%;
		height: 450px;
		background: #ededed;

		img {
			max-width: 1920px;
			min-width: 1200px;
			width: 100%;
			height: 100%;
			display: block;
			margin: 0 auto;
		}
	}

	.nav-box {
		width: 100%;
		border-bottom: 1px solid #E4E7ED;
		box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.09);

		.panel {
			width: 500px;
			margin: 10px auto 0;

			/deep/ .el-tabs__header {
				margin: 0;
			}

			/deep/ .el-tabs__item {
				height: 50px;
				font-size: 1rem;
			}

		}
	}

	.cont {
		width: 1200px;
		min-height: 600px;
		padding: 50px;
		margin: 0 auto;

		.card {
			width: 100%;
			height: 350px;
			// background: rgba(244, 244, 244, 0.9);
			margin-bottom: 20px;

			.item {
				width: 50%;
				overflow: hidden;
				position: relative;

			}

			.text {
				box-sizing: border-box;
				overflow: hidden;

				.title {
					font-size: 1.1rem;
					font-weight: bold;

					&::after {
						content: '';
						display: block;
						width: 100%;
						height: 3px;
						background: #3F7CF8;

					}
				}

				.desc {
					height: 300px;
					width: 100%;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 7;
					overflow: hidden;
				}
			}
		}

		.title-box {
			font-size: .9rem;
			color: #666666;
			margin-bottom: 50px;

			#title {
				color: #333;
				font-size: 1.3rem;
				font-weight: bold;
				margin-bottom: 10px;
			}

		}

		.item {
			min-width: 250px;
			margin-left: 20px;
			margin-bottom: 20px;

			.icon {
				width: 30px;
				height: 30px;
				margin-right: 5px;
			}
		}
	}
</style>
